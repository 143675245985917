import { createContext, useCallback, useContext, useState } from "react";
import { config, preferredNetwork } from "../config";

const Context = createContext({
  ethereum: preferredNetwork?.ethereum,
  tezos: preferredNetwork?.tezos,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setNetworks: (ethereum: string, tezos?: string) => { },
});

export const useNetwork = () => useContext(Context);

export const NetworkContextProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<any>({
    ethereum: localStorage.getItem(`${config.env}_ethereum_network`) || "mainnet",
    tezos: localStorage.getItem(`${config.env}_tezos_network`) || "mainnet",
  });

  const setNetworks = useCallback((ethereum: string, tezos?: string) => {
    localStorage.setItem(`${config.env}_ethereum_network`, ethereum);
    localStorage.setItem(`${config.env}_tezos_network`, tezos || "");
    setValue((prev: any) => ({
      ...prev,
      ethereum,
      tezos,
    }));
  }, []);

  return (
    <Context.Provider
      value={{
        ...value,
        setNetworks,
      }}
    >
      {children}
    </Context.Provider>
  );
};
